import React from 'react';
import { playMediaFile, deleteMediaFile } from '../utils/userApi';

const MediaList = (props: any) => {
  const { mediaitems } = props;
  console.log(mediaitems);
  if (!mediaitems || mediaitems.length === 0)
    return <p>No media available, sorry</p>;
  return (
    <ul>
      <h2 className='list-head text-center'>Available Media</h2>
      {mediaitems.map((item: any) => {
        return (
          <li key={item.id} className='list'>
            <span className='repo-text'>Name: {item.file} </span>
            <span className='repo-description'>Size: {item.size}</span>
            <button
              onClick={() => {
                playMediaFile(item.file);
              }}
            >
              Play
            </button>
            <button
              onClick={() => {
                deleteMediaFile(item.file);
              }}
            >
              delete
            </button>
          </li>
        );
      })}
    </ul>
  );
};
export default MediaList;
