import Axios from 'axios';

const API_URL = process.env.API_URL || 'http://localhost:8082';
const instance = Axios.create({
  baseURL: API_URL,
});
export const getMediaLibrary = async () => {
  return instance.get(`/player/api/v1.0/media/list`);
};
export const deleteMediaFile = async (file: string) => {
  return instance.delete(`/player/api/v1.0/media/${file}`);
};
export const playMediaFile = async (file: string) => {
  return instance.post(`/player/api/v1.0/play/file/${file}`);
};
export const pauseMediaFile = async () => {
  return instance.put(`/player/api/v1.0/play/pause`);
};
export const stopMediaFile = async () => {
  return instance.put(`/player/api/v1.0/play/stop`);
};
export const uploadMedia = (file: any) => {
  const body = new FormData();
  body.append('mediaFile', file);
  return instance.post(
    `http://localhost:8082/player/api/v1.0/media/upload`,
    body
  );
};
