import React from 'react';
interface IProps {
  isLoading: boolean;
  mediaitems: any;
  // any other props that come into the component
}
function WithListLoading(Component: any) {
  return function WihLoadingComponent({ isLoading, ...props }: IProps) {
    if (!isLoading) return <Component {...props} />;
    return (
      <p style={{ textAlign: 'center', fontSize: '30px' }}>
        Hold on, fetching data may take some time :)
      </p>
    );
  };
}
export default WithListLoading;
