import React, { useEffect, useState } from 'react';
import logo from './logo.svg';

import { getMediaLibrary, uploadMedia, pauseMediaFile } from './utils/userApi';
import './App.css';
import MediaList from './components/MediaList';
import WithListLoading from './components/loadingList';

function App() {
  const ListLoading = WithListLoading(MediaList);
  const [appState, setAppState] = useState({
    loading: false,
    medialist: null,
    file: null,
  });
  const onFormSubmit = (e: any) => {
    e.preventDefault(); // Stop form submit
    uploadMedia(appState.file).then((response) => {
      console.log(response.data);
      getMediaLibrary().then((medialist) => {
        const allRepos = medialist.data;
        setAppState({ loading: false, medialist: allRepos, file: null });
      });
    });
  };
  const onChange = (e: any) => {
    setAppState({
      loading: false,
      medialist: appState.medialist,
      file: e.target.files[0],
    });
  };
  useEffect(() => {
    setAppState({ loading: true, medialist: null, file: null });
    getMediaLibrary().then((medialist) => {
      const allRepos = medialist.data;
      setAppState({ loading: false, medialist: allRepos, file: null });
    });
  }, [setAppState]);
  return (
    <div className='App'>
      <div className='container text-center'>
        <h1>MediaList</h1>
      </div>
      <div className='repo-container '>
        <ListLoading
          isLoading={appState.loading}
          mediaitems={appState.medialist}
        />
        <button
          onClick={() => {
            pauseMediaFile();
          }}
        >
          Pause
        </button>
        <form onSubmit={onFormSubmit}>
          <p>File Upload</p>
          <input type='file' onChange={onChange} />
          <button type='submit'>Upload</button>
        </form>
      </div>
      <footer></footer>
    </div>
  );
}

export default App;
